export class Room {
  rId: string;
  createdBy: string;
  createdAt: any;
  name: string;
  endDate: string;
  admins: string[];
  participants: any;
  people: People;
  code: string;
  pass: string;

  constructor(
    rId: string,
    createdBy: string,
    createdAt: any,
    name: string,
    endDate: string,
    admins: string[],
    participants: string[],
    people: People,
    code: string,
    pass: string
  ) {
    this.rId = rId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.name = name;
    this.endDate = endDate;
    this.admins = admins;
    this.participants = participants;
    this.people = people;
    this.code = code;
    this.pass = pass;
  }
}

interface People {
  [key: string]: Person;
}

interface Person {
  name: string;
  initials: string;
}
