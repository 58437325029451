const firebaseConfig = {
  apiKey: "AIzaSyBoYswyP4PWqMR9DvgHX0kSUjdY02yjr-c",
  authDomain: "workhealthy-staging.firebaseapp.com",
  databaseURL: "https://workhealthy-staging.firebaseio.com",
  projectId: "workhealthy-staging",
  storageBucket: "workhealthy-staging.appspot.com",
  messagingSenderId: "19365577138",
  appId: "1:19365577138:web:faabec835a87e4c6fa64ab"
};

export default firebaseConfig;
