import React, { useState } from "react";
import dayjs from "dayjs";

import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import { Divider, LinearProgress, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import DateFnsUtils from "@date-io/dayjs";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const DEFAULT_ROOM_NAME = "A room with a view";
const EMPTY_CREATE_ERROR = "";
const CREATE_ERROR = "There was a problem creating your room";

export const RoomCreateModal = (props: any) => {
  const {
    createRoom,
    created,
    creating,
    joinRoom,
    open,
    onClose,
    roomCode,
    roomPass
  } = props;
  const [createError, setCreateError] = useState(EMPTY_CREATE_ERROR);
  const [roomName, setRoomName] = useState(DEFAULT_ROOM_NAME);
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const cancelButtonClicked = () => {
    onClose();
  };

  const createButtonClicked = () => {
    try {
      setCreateError(EMPTY_CREATE_ERROR);
      createRoom(roomName, endDate);
    } catch (e) {
      console.log(CREATE_ERROR, e);
      setCreateError(CREATE_ERROR);
    }
  };

  const joinButtonClicked = () => {
    joinRoom();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create room</DialogTitle>
      <DialogContent>
        <DialogContentText>Quick and easy!</DialogContentText>
        <TextField
          autoFocus
          defaultValue={roomName}
          disabled={creating || created}
          margin="dense"
          id="room-name"
          label="Room name"
          type="text"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setRoomName(event.target.value)}
          fullWidth
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disabled={creating || created}
            disableToolbar
            format="YYYY-MM-DD"
            margin="normal"
            id="room-last-open-day"
            label="Room stays open until end of"
            value={endDate}
            onChange={(newDate: any) =>
              setEndDate(newDate.format("YYYY-MM-DD"))
            }
            disablePast={true}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
          />
        </MuiPickersUtilsProvider>
        {creating ? <LinearProgress color="secondary" /> : ""}
        {created ? (
          <>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Typography variant="subtitle2">Room code</Typography>
            <Typography>{roomCode}</Typography>
            <Typography style={{ marginTop: 10 }} variant="subtitle2">
              Room password
            </Typography>
            <Typography>{roomPass}</Typography>
          </>
        ) : (
          ""
        )}
        {createError !== EMPTY_CREATE_ERROR ? (
          <Typography color="error">{createError}</Typography>
        ) : (
          ""
        )}
      </DialogContent>

      {created ? (
        <DialogActions>
          <Button onClick={joinButtonClicked} color="primary">
            Join
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            disabled={creating}
            onClick={cancelButtonClicked}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={creating}
            onClick={createButtonClicked}
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
