import Firebase from "firebase";
import { authState } from "rxfire/auth";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { FirebaseService } from "./FirebaseService";

export class AuthService {
  private static _instance: AuthService;

  auth: any;
  user$: Observable<string | null>;
  loggedIn$: Observable<Firebase.User | boolean>;

  private constructor() {
    this.auth = FirebaseService.Instance.app.auth();
    this.user$ = authState(this.auth).pipe(
      map((user) => (user ? user.uid : null))
    );
    this.loggedIn$ = authState(this.auth).pipe(map((user) => !!user));

    // TODO: catch errors and send them down loggedIn$
    this.auth.signInAnonymously();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}
