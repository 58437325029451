import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@material-ui/core";

import { RoomCreateModal } from "./RoomCreateModal";

import { RoomService } from "../services/RoomService";

export function RoomCreate(props: any) {
  const { user } = props;
  const [created, setCreated] = useState(false);
  const [creating, setCreating] = useState(false);
  const [open, setOpen] = useState(false);
  const [roomCode, setRoomCode] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [roomPass, setRoomPass] = useState(null);
  const history = useHistory();

  const handleOpen = () => {
    setCreated(false);
    setCreating(false);
    setRoomCode(null);
    setRoomId(null);
    setRoomPass(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createRoom = async (roomName: string, endDate: string) => {
    if (user === undefined) {
      console.log("Attempting to create a room before logged in");
      return;
    }

    setCreating(true);
    const roomDoc = await RoomService.Instance.createRoom(endDate, roomName);
    setCreating(false);
    setCreated(true);
    setRoomCode(roomDoc.data.roomCode);
    setRoomId(roomDoc.data.id);
    setRoomPass(roomDoc.data.roomPass);
  };

  const joinRoom = async () => {
    if (roomId !== null) {
      await RoomService.Instance.setUpRoom(roomId!);
      history.push("/room/" + roomId);
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        fullWidth
        color="primary"
        size="large"
      >
        Create room
      </Button>
      <RoomCreateModal
        created={created}
        createRoom={createRoom}
        creating={creating}
        joinRoom={joinRoom}
        onClose={handleClose}
        open={open}
        roomCode={roomCode}
        roomPass={roomPass}
      />
    </>
  );
}
