import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  LinearProgress,
  DialogActions,
  Button,
  Avatar
} from "@material-ui/core";
import React, { useState } from "react";

import { hashStringToColor } from "../utils";

export const RoomJoinModal = (props: any) => {
  const { open, savePersonDetails } = props;
  const [name, setName] = useState("");
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  const letsGoButtonClicked = () => {
    console.log("Let's go!");
  };

  const onClose = () => {
    console.log("onClose!");
  };

  const ILLEGAL_NAME_CHARS = "!@£$%^&*()_+-=1234567890[]{};:|,./<>?`~\\\"'";
  const nameChanged = (name: string) => {
    var newName = "";
    for (var i = 0; i < name.length; i++) {
      const nameChar = name[i];
      if (ILLEGAL_NAME_CHARS.indexOf(nameChar) === -1) {
        newName += nameChar;
      }
    }

    setName(newName);
  };

  const nameValid = name !== undefined && name.length > 1;
  const avatarInitials =
    name !== undefined ? name.slice(0, 2).toLocaleUpperCase() : "";
  const avatarColour =
    name === undefined || name === "" ? "" : hashStringToColor(name);

  const saveButtonClicked = async () => {
    setSaving(true);
    try {
      await savePersonDetails(name, avatarInitials, avatarColour);
      setSaved(true);
    } catch (e) {}

    setSaving(false);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Welcome!</DialogTitle>
      <DialogContent>
        <DialogContentText>Please introduce yourself</DialogContentText>
        <TextField
          autoFocus
          autoComplete="off"
          disabled={saving || saved}
          margin="dense"
          id="person-name"
          label="What's your name?"
          placeholder="Jamie Jones"
          type="text"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => nameChanged(event.target.value)}
          fullWidth
          value={name}
        />
        {saving ? <LinearProgress color="secondary" /> : ""}
      </DialogContent>

      {saved ? (
        <DialogActions>
          <Avatar alt={name}>{avatarInitials}</Avatar>
          <Button onClick={letsGoButtonClicked} color="primary">
            Let's go!
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Avatar
            style={{
              backgroundColor: avatarColour
            }}
            alt={name}
          >
            {avatarInitials}
          </Avatar>
          <Button
            disabled={!nameValid || saving}
            onClick={saveButtonClicked}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
