import Firebase from "firebase";
import firebaseConfig from "../config/firebase_config";

export class FirebaseService {
  private static _instance: FirebaseService;
  public app: Firebase.app.App;
  public firestore: Firebase.firestore.Firestore;
  public functions: Firebase.functions.Functions;

  private constructor() {
    this.app = Firebase.initializeApp(firebaseConfig);
    this.firestore = this.app.firestore();
    this.functions = Firebase.functions();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }
}
