import React, { useState } from "react";

import {
  AppBar,
  Box,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  SvgIcon,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer/Drawer";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import CopyToClipboard from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

export const InvitationDetailsIcon = (props: any) => {
  const { room } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const classes = useStyles();

  const roomText = room === undefined ? "" : `${room.code} - ${room.pass}`;

  const toggleDrawer = (newDrawerOpenState: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(newDrawerOpenState);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="deleted"
        disabled={room === undefined}
        onClick={toggleDrawer(true)}
      >
        <PersonAddIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <AppBar position="sticky" color="default" elevation={0}>
          <div className={classes.root}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Invite others
              </Typography>
              <IconButton
                color="inherit"
                aria-label="close-deleted"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </div>
        </AppBar>
        <Container style={{ padding: 20, width: "300px" }}>
          {room === undefined ? (
            <LinearProgress color="secondary" />
          ) : (
            <>
              <Typography variant="subtitle1">
                Room code and password
              </Typography>
              <Box display="flex">
                <Box flexGrow={1} p={1}>
                  <TextField
                    defaultValue={roomText}
                    id="copyable-details"
                    InputProps={{
                      readOnly: true
                    }}
                    label="Ready to paste!"
                    style={{ marginTop: 15, paddingRight: 0, width: "27ch" }}
                    // value={`${room.code} - ${room.pass}`}
                    variant="outlined"
                  />
                </Box>
                <Divider />
                <Box
                  p={1}
                  style={{ cursor: "pointer", paddingLeft: 0, paddingTop: 25 }}
                >
                  <CopyToClipboard text={roomText}>
                    <IconButton aria-label="copy">
                      <SvgIcon {...props}>
                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                      </SvgIcon>
                    </IconButton>
                  </CopyToClipboard>
                </Box>
              </Box>
              <Typography style={{ marginTop: 15 }} variant="subtitle1">
                Invitation URL
              </Typography>
              <Typography>COMING SOON!</Typography>
            </>
          )}
        </Container>
      </Drawer>
    </>
  );
};
