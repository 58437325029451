import Grid from "@material-ui/core/Grid/Grid";
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

import QuestionCard from "./QuestionCard";
import { Question } from "../models/question";

const useStyles = makeStyles({
  gridContainer: {
    // padding: "20px"
    width: "100%",
    paddingLeft: "20px",
    paddingTop: "20px"
  },
  grid: {
    // margin: "20px"
  }
});

// https://stackoverflow.com/questions/4408937/font-size-auto-adjust-to-fit

export function QuestionGrid(props: any) {
  const { people, questions, user } = props;
  const classes = useStyles();

  return questions === undefined ? (
    <h1>No questions yet!</h1>
  ) : (
    <Grid container spacing={4} className={classes.gridContainer}>
      {questions.map((q: Question) => (
        <Grid
          key={q.qId}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={2}
          className={classes.grid}
        >
          <QuestionCard
            q={q}
            user={user}
            voteable={true}
            asker={people[q.createdBy]}
          />
        </Grid>
      ))}
    </Grid>
  );
}
