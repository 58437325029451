import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  LinearProgress,
  Box
} from "@material-ui/core";

import { QuestionForm } from "../components/QuestionForm";
import { QuestionGrid } from "../components/QuestionGrid";
import { RoomAppBar } from "../components/RoomAppBar";
import { RoomAvatarGroup } from "../components/RoomAvatarGroup";
import { RoomJoinModal } from "../components/RoomJoinModal";

import { RoomService } from "../services/RoomService";
import { useObservable } from "../utils";

const USER_OBJECT_KEYS = ["name"];
const userInfoMissing = (userObject: any) => {
  if (!userObject) {
    return false;
  }

  for (var i = 0; i < USER_OBJECT_KEYS.length; i++) {
    const key = USER_OBJECT_KEYS[i];
    if (!(key in userObject) || userObject[key] === "") {
      return true;
    }
  }

  return false;
};

export const RoomContainer = (props: any) => {
  const { user } = props;
  let { roomId } = useParams<{ roomId: string }>();

  useEffect(() => {
    async function setUpRoom() {
      await RoomService.Instance.setUpRoom(roomId);
    }
    setUpRoom();
  }, [user, roomId]);

  const room = useObservable(RoomService.Instance.currentRoom$);
  const questions = useObservable(RoomService.Instance.question$);

  const userDetailsNeeded =
    user !== undefined &&
    room !== undefined &&
    user in room.people &&
    userInfoMissing(room.people[user]);

  const savePersonDetails = async (
    name: string,
    initials: string,
    colour: string
  ) => {
    if (room !== undefined) {
      await RoomService.Instance.addPersonDetails(
        room.rId,
        name,
        initials,
        colour
      );
    } else {
      console.log(
        "RoomContainer: trying to save person details on an undefined room"
      );
    }
  };

  if (user === undefined || room === undefined) {
    return (
      <>
        <RoomAppBar user={user} />
        <LinearProgress color="secondary" />
      </>
    );
  } else {
    return (
      <>
        <RoomAppBar user={user} />
        <RoomJoinModal
          open={userDetailsNeeded}
          savePersonDetails={savePersonDetails}
        />
        <Container maxWidth="md" style={{ paddingTop: 20 }}>
          <Box display="flex">
            <Box flexGrow={1} p={1}>
              <Typography variant="h6">{room.name}</Typography>
            </Box>
            <Box p={1}>
              <RoomAvatarGroup maxShown={4} people={room.people} />
            </Box>
          </Box>
          <QuestionForm
            addQuestion={(q: string) => {
              RoomService.Instance.addQuestion(q, user, roomId);
            }}
          />
        </Container>
        {questions === undefined ||
        questions == null ||
        questions.length === 0 ? (
          <Grid style={{ marginTop: "50px" }} container justify="center">
            <Typography variant="h6">
              Who's going to ask the first question?
            </Typography>
          </Grid>
        ) : (
          <QuestionGrid
            people={room.people}
            questions={questions}
            user={user}
          />
        )}
      </>
    );
  }
};
