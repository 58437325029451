import React from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

export const RoomAvatarGroup = (props: any) => {
  const { maxShown, people } = props;
  var peopleAvatars: any[] = [];
  Object.keys(people).forEach((key) => {
    const p = people[key];
    //   console.log(bigObject[key])
    // })
    // for (var i = 0; i < people.length; i++) {
    //   const p = people[i];
    peopleAvatars.push(
      <Tooltip key={key} title={p.name}>
        <Avatar style={{ backgroundColor: p.colour }}>{p.initials}</Avatar>
      </Tooltip>
    );
  });
  //   );
  // }
  // const peopleAvatars = people.forEach((p: any) => (
  //   <Tooltip title={p.name}>
  //     <Avatar style={{ backgroundColor: p.colour }}>{p.initials}</Avatar>
  //   </Tooltip>
  // ));

  return <AvatarGroup max={maxShown}>{peopleAvatars}</AvatarGroup>;
};
