import { Box, Container, LinearProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { RoomService } from "../services/RoomService";
import { useObservable } from "../utils";

const ROOM_CODE_LENGTH = 8;
const ROOM_PASS_LENGTH = 6;
const DISALLOWED_CHARS = /[oO0 -]/g;

export function RoomForm() {
  const [roomCode, setRoomCode] = useState("");
  const [roomPass, setRoomPass] = useState("");
  const [loadingState, setLoadingState] = useState("new");
  const [joiningError, setJoiningError] = useState("");
  const user = useObservable(AuthService.Instance.user$);
  const history = useHistory();

  const buttonDisabled =
    roomCode.length !== ROOM_CODE_LENGTH ||
    roomPass.length !== ROOM_PASS_LENGTH ||
    loadingState === "loading" ||
    loadingState === "error";
  const tooManyCharacters =
    roomCode.length + roomPass.length > ROOM_CODE_LENGTH + ROOM_PASS_LENGTH;

  const joinRoom = async () => {
    if (user === undefined) {
      console.log("Attempting to create a room before logged in");
      return;
    }

    try {
      setLoadingState("loading");
      const result = await RoomService.Instance.addParticipantToRoom(
        roomCode,
        roomPass
      );

      history.push("/room/" + result.data.roomId);
    } catch (e) {
      setLoadingState("error");
      setJoiningError("Could not join that room");

    }
  };

  const setRoomCodeSlick = function (codePass: string) {
    if (codePass) {
      codePass = codePass.replace(DISALLOWED_CHARS, "");

      const code = codePass.substr(0, ROOM_CODE_LENGTH).toUpperCase();
      const pass = codePass
        .substr(ROOM_CODE_LENGTH, ROOM_PASS_LENGTH)
        .toUpperCase();

      setRoomCode(code);
      setRoomPass(pass);
    } else {
      setRoomCode("");
      setRoomPass("");
    }
    setLoadingState("new");
  };

  const getCodeHelperText = function () {
    var text = "";
    if (tooManyCharacters) {
      text += "Too many characters!";
    } else if (loadingState === "error") {
      text += "Could not join room";
    } else {
      text += "Enter your code and your password";
    }

    return (
      text +
      " (" +
      (roomCode.length + roomPass.length) +
      "/" +
      (ROOM_CODE_LENGTH + ROOM_PASS_LENGTH) +
      ")"
    );
  };

  return (
    <>
      {" "}
      <Box
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between"
        }}
        component="span"
      >
        {loadingState === "loading" ? (
          <Container style={{ marginLeft: "-25px", marginTop: "28px" }}>
            <LinearProgress color="secondary" />
          </Container>
        ) : (
          <TextField
            style={{ width: "65%" }}
            error={loadingState === "error"}
            helperText={joiningError || getCodeHelperText()}
            onChange={(e) => setRoomCodeSlick(e.target.value)}
            placeholder="A1B2C3D4 - X8Y9Z0"
            value={roomCode + (roomPass ? " - " + roomPass : "")}
            disabled={loadingState === "loading"}
          />
        )}
        <Button
          color="primary"
          disabled={buttonDisabled}
          onClick={joinRoom}
          size="small"
          variant="contained"
        >
          Join room
        </Button>
      </Box>{" "}
    </>
  );
}
