import { Box, Container, Divider, LinearProgress } from "@material-ui/core";
import React from "react";
import { PreRoomAppBar } from "../components/PreRoomAppBar";
import { RoomCreate } from "../components/RoomCreate";
import { RoomForm } from "../components/RoomForm";
import { AuthService } from "../services/AuthService";
import { useObservable } from "../utils";

export const PreRoomContainer = () => {
  const user = useObservable(AuthService.Instance.user$);

  if (user === undefined) {
    return (
      <Container maxWidth="xs">
        <p>Connecting</p>
        <LinearProgress color="secondary" />
      </Container>
    );
  }

  return (
    <>
      <PreRoomAppBar />
      <Box
        style={{
          paddingTop: "50px",
          height: "500px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around"
        }}
      >
        <Container maxWidth="xs">
          <RoomCreate user={user} />
        </Container>
        <Divider />
        <Container maxWidth="xs">
          <RoomForm />
        </Container>
      </Box>
    </>
  );
};
