import Firebase from "firebase";
import { Subject } from "rxjs";
import { Question, questionSort } from "../models/question";
import { Room } from "../models/room";

import { FirebaseService } from "./FirebaseService";

// https://stackoverflow.com/questions/42840891/how-to-combine-multiple-rxjs-behavioursubjects

export class RoomService {
  private static _instance: RoomService;

  private addParticipantToRoomInFirebase: firebase.functions.HttpsCallable;
  private addRoomInFirebase: firebase.functions.HttpsCallable;
  private addPersonDetailsInFirebase: firebase.functions.HttpsCallable;

  private roomsRef: Firebase.firestore.CollectionReference<
    Firebase.firestore.DocumentData
  >;
  private currentRoomRef: null | Firebase.firestore.DocumentReference<
    Firebase.firestore.DocumentData
  >;
  private questionsRef: null | Firebase.firestore.CollectionReference<
    Firebase.firestore.DocumentData
  > = null;
  roomId: null | string = null;

  currentRoom$: Subject<Room>;
  question$: Subject<Question[]>;
  deletedQuestion$: Subject<Question[]>;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  private constructor() {
    this.roomsRef = FirebaseService.Instance.firestore.collection("rooms");
    this.currentRoomRef = null;
    this.addParticipantToRoomInFirebase = FirebaseService.Instance.functions.httpsCallable(
      "addParticipantToRoom"
    );
    this.addPersonDetailsInFirebase = FirebaseService.Instance.functions.httpsCallable(
      "addPersonDetails"
    );
    this.addRoomInFirebase = FirebaseService.Instance.functions.httpsCallable(
      "addRoom"
    );

    this.currentRoom$ = new Subject();
    this.question$ = new Subject();
    this.deletedQuestion$ = new Subject();
  }

  // public async createRoom(user: string, roomName: string, endDate: string) {
  //   // Create room
  //   const newRoom = {
  //     created_by: user,
  //     created_at: Firebase.database.ServerValue.TIMESTAMP,
  //     name: roomName,
  //     end_date: endDate,
  //     admins: [user]
  //   };
  //   const roomDoc = await this.roomsRef.add(newRoom);

  //   return roomDoc.id;
  // }

  public async createRoom(endDate: string, roomName: string) {
    // Create room
    const newRoom = {
      created_at: Firebase.database.ServerValue.TIMESTAMP,
      name: roomName,
      end_date: endDate
    };

    try {
      const roomDoc = await this.addRoomInFirebase(newRoom);
      return roomDoc;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async addPersonDetails(
    roomId: string,
    personName: string,
    personInitials: string,
    personColour: string
  ) {
    const personDetails = {
      person_name: personName,
      person_initials: personInitials,
      person_colour: personColour,
      room_id: roomId
    };

    try {
      await this.addPersonDetailsInFirebase(personDetails);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async setUpRoom(roomId: string) {
    // Set room in this object
    this.roomId = roomId;
    this.currentRoomRef = FirebaseService.Instance.firestore
      .collection("rooms")
      .doc(roomId);
    this.currentRoomRef.onSnapshot(
      (
        querySnapshot: Firebase.firestore.DocumentSnapshot<
          Firebase.firestore.DocumentData
        >
      ) => {
        this.currentRoom$.next(RoomService.docToRoom(querySnapshot));
      },
      (err) => {
        console.log("Could not subscribe to currentRoom$", err);
      }
    );

    // Set questions ref
    this.questionsRef = this.currentRoomRef.collection("questions");
    this.questionsRef.onSnapshot(
      (
        querySnapshot: Firebase.firestore.QuerySnapshot<
          Firebase.firestore.DocumentData
        >
      ) => {
        this.updateQuestionSubjects(querySnapshot);
      },
      (err) => {
        console.log("Could not subscribe to question$", err);
      }
    );
  }

  public async addParticipantToRoom(roomCode: string, roomPass: string) {
    const result = await this.addParticipantToRoomInFirebase({
      roomCode: roomCode,
      roomPass: roomPass
    });

    return result;
  }

  public addQuestion(text: string, user: string, roomId: string) {
    if (this.questionsRef) {
      this.questionsRef.add({
        text: text,
        created_by: user,
        upvotes: [user]
      });
    } else {
      console.log("Trying to add a question to a null questionRef");
    }
  }

  public upvoteQuestion(qId: string, user: string) {
    if (this.questionsRef !== null) {
      this.questionsRef.doc(qId).update({
        upvotes: Firebase.firestore.FieldValue.arrayUnion(user)
      });
      // RoomService.Instance.questionsRef
      //   .doc(q.qId)
      //   .collection("upvotes")
      //   .doc(user)
      //   .set({});
    }
  }

  public removeVoteQuestion(qId: string, user: string) {
    if (this.questionsRef !== null) {
      this.questionsRef.doc(qId).update({
        upvotes: Firebase.firestore.FieldValue.arrayRemove(user)
      });
      // RoomService.Instance.questionsRef
      //   .doc(q.qId)
      //   .collection("upvotes")
      //   .doc(user)
      //   .delete();
    }
  }

  public deleteQuestion(qId: string) {
    if (RoomService.Instance.questionsRef !== null)
      RoomService.Instance.questionsRef.doc(qId).update({ deleted: true });
  }

  public undeleteQuestion(qId: string) {
    if (RoomService.Instance.questionsRef !== null)
      RoomService.Instance.questionsRef.doc(qId).update({ deleted: false });
  }

  private updateQuestionSubjects(
    querySnapshot: Firebase.firestore.QuerySnapshot<
      Firebase.firestore.DocumentData
    >
  ) {
    this.question$.next(
      this.flowQuestions(querySnapshot, (q: Question) => q.deleted !== true)
    );

    this.deletedQuestion$.next(
      this.flowQuestions(querySnapshot, (q: Question) => q.deleted === true)
    );
  }

  private flowQuestions(
    querySnapshot: Firebase.firestore.QuerySnapshot<
      Firebase.firestore.DocumentData
    >,
    filter: (q: Question) => boolean
  ) {
    return querySnapshot.docs
      .map(RoomService.docToQuestion)
      .filter(filter)
      .sort(questionSort);
  }

  private static docToRoom(
    doc: Firebase.firestore.DocumentSnapshot<Firebase.firestore.DocumentData>
  ) {
    const data = doc.data();
    const r = new Room(
      doc.id,
      data!.created_by,
      data!.created_at,
      data!.name,
      data!.end_date,
      data!.admins,
      data!.participants,
      data!.people,
      data!.room_code,
      data!.room_pass
    );

    return r;
  }

  private static docToQuestion(
    doc: Firebase.firestore.QueryDocumentSnapshot<
      Firebase.firestore.DocumentData
    >
  ) {
    const data = doc.data();
    const q = new Question(
      doc.id,
      data.created_by,
      data.text,
      doc,
      data.deleted
    );
    if (data.upvotes) {
      q.upvotes = data.upvotes;
    }

    return q;
  }
}
