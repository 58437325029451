import { useEffect, useState } from "react";
import { Observable } from "rxjs";

export function useObservable<T>(observable: Observable<T>): T | undefined {
  const [state, setState] = useState<T>();

  useEffect(() => {
    const sub = observable.subscribe(setState);
    return () => sub.unsubscribe();
  }, [observable]);

  return state;
}

const djb2 = (str: string) => {
  var hash = 5381;
  for (var i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* hash * 33 + c */
  }
  return hash;
};

export const hashStringToColor = (str: string) => {
  const hash = djb2(str);
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  return (
    "#" +
    ("0" + r.toString(16)).substr(-2) +
    ("0" + g.toString(16)).substr(-2) +
    ("0" + b.toString(16)).substr(-2)
  );
};

const funPlaceholders = [
  "What's your favourite band?",
  "How's the weather where you are?",
  "How are you finding working remotely?",
  "What's your favourite colour?",
  "How many more questions are we allowed?",
  "What's your favourite question?",
  "What's your favourite thing about this tool?",
  "Is this thing on?",
  "Did you see the game at the weekend?",
  "Where will we be in five years?"
];

export function getRandomPlaceholder() {
  return funPlaceholders[Math.floor(Math.random() * funPlaceholders.length)];
}
