import { Subject } from "rxjs";

export class EnvironmentService {
    private static _instance: EnvironmentService;

    environment$: Subject<null | String>;

    private constructor() {
        this.environment$ = new Subject();
        this.environment$.next(null);

        fetch("/environment")
            .then(
                (response) => response.text()
                    .then((value: string) => this.environment$.next(value.trim()))
            );  
    }
  
    public static get Instance() {
      return this._instance || (this._instance = new this());
    }
  }
  