import React, { useState } from "react";
import { Button } from "@material-ui/core";
import Confetti from "react-dom-confetti";

import { RoomService } from "../services/RoomService";

export const QuestionVoteButton = (props: any) => {
  const { q, user } = props;
  const [fireConfetti, setFireConfetti] = useState(q.upvotes.includes(user));

  const upvoteClicked = () => {
    RoomService.Instance.upvoteQuestion(q.qId, user);
    setFireConfetti(true);
  };

  const downvoteClicked = () => {
    RoomService.Instance.removeVoteQuestion(q.qId, user);
    setFireConfetti(false);
  };

  const config = {
    angle: 90,
    spread: 122,
    startVelocity: 56,
    elementCount: 70,
    dragFriction: 0.53,
    duration: 750,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <>
      <Confetti active={fireConfetti} config={config} />
      {q.upvotes.includes(user) ? (
        <>
          <Button
            size="small"
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => downvoteClicked()}
            disabled={user === q.createdBy}
          >
            vote
          </Button>
        </>
      ) : (
        <Button
          size="small"
          onClick={() => upvoteClicked()}
          variant="outlined"
          disabled={user === q.createdBy}
        >
          vote
        </Button>
      )}
    </>
  );
};
