import React from "react";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  LinearProgress,
  Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

import { DeletedQuestionsIcon } from "./DeletedQuestionsIcon";
import { InvitationDetailsIcon } from "./InvitationDetailsIcon";
import { RoomService } from "../services/RoomService";
import { useObservable } from "../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(1)
  },
  title: {
    flexGrow: 1
  }
}));

export const RoomAppBar = (props: any) => {
  const { user } = props;

  const room = useObservable(RoomService.Instance.currentRoom$);
  const classes = useStyles();

  return (
    <AppBar position="sticky">
      <div className={classes.root}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          {/* <Box>
            <Slide in={!trigger} direction="right"> */}
          {room === undefined ? (
            <Container maxWidth="xs">
              <LinearProgress color="secondary" />
            </Container>
          ) : (
            <Typography variant="h6" className={classes.title}>
              The Question Box!
            </Typography>
          )}
          {/* </Slide>
            <Slide in={!!trigger} direction="up">
              <Box>
                <TextField 
                  required
                  id="questionInput"
                  size="medium"
                  name="questionText"
                  onChange={(e) => setQuestion(e.target.value)}
                  variant="outlined"
                />
                <Button>Ask?</Button>
              </Box>
            </Slide>
          </Box> */}
          {room !== undefined && user !== undefined ? (
            <Box>
              <Typography variant="body2">Last day {room.endDate}</Typography>
              <Box display="flex">
                <Box flexGrow={1}></Box>
                <Box style={{ marginTop: -2 }}>
                  <DeletedQuestionsIcon room={room} user={user} />
                </Box>
                <Box>
                  <InvitationDetailsIcon room={room} />
                </Box>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Toolbar>
      </div>
    </AppBar>
  );
};
