import React from "react";

import { Avatar, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import Card from "@material-ui/core/Card/Card";
import CardActions from "@material-ui/core/CardActions/CardActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";

import { RoomService } from "../services/RoomService";

import { QuestionVoteButton } from "./QuestionVoteButton";

export default function QuestionCard(props: any) {
  const { asker, q, user, voteable } = props;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {q.text}
        </Typography>
      </CardContent>
      <CardActions>
        {voteable ? (
          <QuestionVoteButton q={q} user={user} />
        ) : (
          <Typography variant="h6">Votes:</Typography>
        )}

        <Typography variant="h6" component="h2">
          {q.upvotes.length}
        </Typography>
        {q.deleted === true ? (
          <IconButton
            onClick={() => RoomService.Instance.undeleteQuestion(q.qId)}
            disabled={user !== q.createdBy}
          >
            <RestoreFromTrashIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => RoomService.Instance.deleteQuestion(q.qId)}
            disabled={user !== q.createdBy}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <Tooltip title={asker.name}>
          <Avatar style={{ backgroundColor: asker.colour }}>
            {asker.initials}
          </Avatar>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
