import React from "react";
import { useState } from "react";

import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";

import { getRandomPlaceholder } from "../utils";

const MAX_QUESTION_LENGTH = 200;

export function QuestionForm(props: any) {
  const { addQuestion } = props;
  const [question, setQuestion] = useState("");

  const submitQuestion = (e: any) => {
    if (question.length > MAX_QUESTION_LENGTH) return false;
    addQuestion(question);
    setQuestion("");
    e.preventDefault();
    e.target.reset();
  };

  const helperTextCreator = () => {
    var text = "";
    if (question.length <= MAX_QUESTION_LENGTH) {
      text += "For best results be succinct, pleasant, and inquisitive!";
    } else {
      text += "You've gone over " + MAX_QUESTION_LENGTH + " characters :(";
    }

    text += " (" + question.length + "/" + MAX_QUESTION_LENGTH + ")";

    return text;
  };

  const helperText = helperTextCreator();

  return (
    <form autoComplete="off" onSubmit={submitQuestion}>
      <TextField
        helperText={helperText}
        label="Ask a question!"
        placeholder={getRandomPlaceholder()}
        margin="normal"
        fullWidth
        required
        id="questionInput"
        size="medium"
        error={question.length > MAX_QUESTION_LENGTH}
        name="questionText"
        onChange={(e) => setQuestion(e.target.value)}
        variant="outlined"
      />
      <Button
        type="submit"
        disabled={!question || question.length > MAX_QUESTION_LENGTH}
        fullWidth
        size={!question ? "small" : "large"}
        variant="contained"
        color="primary"
      >
        Ask
      </Button>
    </form>
  );
}
