import React, { useState } from "react";

import {
  AppBar,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Drawer from "@material-ui/core/Drawer/Drawer";

import { useObservable } from "../utils";
import { RoomService } from "../services/RoomService";
import { Question } from "../models/question";
import QuestionCard from "./QuestionCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

export const DeletedQuestionsIcon = (props: any) => {
  const { room, user } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const deletedQuestions = useObservable(RoomService.Instance.deletedQuestion$);

  const myDeletedQuestions =
    deletedQuestions === undefined
      ? []
      : deletedQuestions.filter(
          (q: Question) => user !== undefined && user === q.createdBy
        );

  const myDeletedQuestionsPresent = !!myDeletedQuestions.length;

  const classes = useStyles();

  const toggleDrawer = (newDrawerOpenState: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(newDrawerOpenState);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="deleted"
        disabled={!myDeletedQuestionsPresent}
        onClick={toggleDrawer(true)}
      >
        <DeleteIcon />
        <Typography variant="subtitle1">
          {myDeletedQuestionsPresent ? myDeletedQuestions.length : "0"}
        </Typography>
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <AppBar position="sticky" color="default" elevation={0}>
          <div className={classes.root}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Recycle Bin
              </Typography>
              <IconButton
                color="inherit"
                aria-label="close-deleted"
                onClick={toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </div>
        </AppBar>
        <Container style={{ padding: 20, width: "300px" }}>
          {myDeletedQuestionsPresent ? (
            <Grid container spacing={4}>
              {myDeletedQuestions.map((q: Question) => (
                <Grid key={q.qId} item xs={12}>
                  <QuestionCard
                    asker={room.people[user]}
                    q={q}
                    user={user}
                    voteable={false}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <p>You haven't got any deleted questions.</p>
          )}
        </Container>
      </Drawer>
    </>
  );
};
