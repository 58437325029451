import QuestionComment from "./question_comment";

export class Question {
  qId: string;
  createdBy: string = "";
  text: string;
  doc: any;
  deleted: boolean = false;
  upvotes: string[];
  comments: QuestionComment[] = [];

  constructor(
    qId: string,
    createdBy: string,
    text: string,
    doc: any,
    deleted: boolean,
    upvotes: string[] = []
  ) {
    this.qId = qId;
    this.createdBy = createdBy;
    this.text = text;
    this.doc = doc;
    this.deleted = deleted;
    this.upvotes = upvotes;
  }
}

export const docToQuestion = (doc: any) => {
  const data = doc.data();
  const q = new Question(doc.id, data.created_by, data.text, doc, data.deleted);
  if (data.upvotes) {
    q.upvotes = data.upvotes;
  }

  return q;
};

export const questionSort = (q1: Question, q2: Question) => {
  const q1Score = q1.upvotes.length;
  const q2Score = q2.upvotes.length;

  return q2Score - q1Score;
};
